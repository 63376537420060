import React from 'react';
import SEO from '../../../structure/seo';
import Layout from '../../../structure/layout';
import DashboardStructure from '../../../structure/general/DashboardStructure';
import Index from '../../../structure/pages/dashboard/expiration/details';


const DashboardDetails = () => {
    return (
        <Layout shadowNav={false} noHeader noFooter mainBg="#E1E5EA">
            <SEO title={'Dashboard - Vencimientos-detalles'} />
            <DashboardStructure>
                <Index />
            </DashboardStructure>
        </Layout>
    );
}

export default DashboardDetails;
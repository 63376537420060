import React, { useState, useRef, useCallback } from 'react';
import { SearchState, IntegratedFiltering, EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, Toolbar, TableColumnResizing, SearchPanel, VirtualTable, TableEditRow, TableEditColumn, ExportPanel } from '@devexpress/dx-react-grid-bootstrap4';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import styled from "styled-components";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import clienteAxios from '../../../../config/axios';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import saveAs from 'file-saver';
import { DeleteClientAction } from '../../../../redux/actions/deleteClient';


const Info = styled.div`
    
`
const P = styled.p`
    text-align: justify;
    color:#000;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    color:#1D2146;
    font-size: 1rem;
    font-weight: bold;
`
const getRowId = row => row.id;


const Expiration = ({ location }) => {

    const token = useSelector(state => state.auth.token)
    const role = useSelector(state => state.auth.role)

    const url = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(url);
    const id = urlParams.get('id')

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const DeleteClientFn = (id, token) => dispatch(DeleteClientAction(id, token));

    const exporterRef = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [spaceData, setSpaceData] = useState(null)
    const [paymentsData, setPaymentsData] = useState([])
    const [cuotesQtyExpired, setCuotesQtyExpired] = useState(0)
    const [dataHeaderExcel, setDataHeaderExcel] = useState([])

    React.useEffect(() => {
        if (id) {
            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };

            clienteAxios.get(`/api/space/${id}`, config)
                .then((res) => {
                    console.log(res.data)
                    let data = res.data
                    setSpaceData(res.data)

                    let header = [
                        data.client.name + " " + data.client.lastname,
                        data.lote,
                        data.mensura,
                        `${data.cuotas} en ${data.divisa}`,
                        data.barrio,
                        formatterPeso.format(data.saldoTotal),
                        formatterPeso.format(data.saldoALaFecha),
                        formatterPeso.format(data.pagoTotal)
                    ]
                    // console.log(header)
                    setDataHeaderExcel(header)
                })
                .catch(error => {
                    // console.log(error)
                })

            clienteAxios.get(`/api/payment/space/${id}`, config)
                .then((res) => {
                    console.log(res.data)
                    let payments = []
                    let qtyCuotesExpired = 0
                    for (let i = 0; i < res.data.length; i++) {
                        let payment = res.data[i]
                        if (payment.paymentStatus === 'Vencido') {
                            qtyCuotesExpired = qtyCuotesExpired + 1
                        }
                        payments.push({
                            fecha: payment.paymentDate ? payment.paymentDate.split("T")[0].split("-")[2] + "-" + payment.paymentDate.split("T")[0].split("-")[1] + "-" + payment.paymentDate.split("T")[0].split("-")[0] : "",
                            concepto: payment.concept,
                            vencimiento: payment.expiration.split("T")[0].split('-')[2] + "-" + payment.expiration.split("T")[0].split('-')[1] + "-" + payment.expiration.split("T")[0].split('-')[0],
                            retrazo: payment.delay.toString() + " días",
                            apagar: payment.price,
                            pago: payment.paymentAmount ? payment.paymentAmount : 0,
                            estado: payment.paymentStatus,
                            id: payment._id,
                        })
                    }
                    setCuotesQtyExpired(qtyCuotesExpired)
                    setPaymentsData(payments)
                })
                .catch(error => {
                    // console.log(error)
                })
        }
    }, [])

    const getDataTableAfterUpdate = (data) => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/payment/space/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                let payments = []
                let qtyCuotesExpired = 0
                for (let i = 0; i < res.data.length; i++) {
                    let payment = res.data[i]
                    if (payment.paymentStatus === 'Vencido') {
                        qtyCuotesExpired = qtyCuotesExpired + 1
                    }
                    payments.push({
                        fecha: payment.paymentDate ? payment.paymentDate.split("T")[0].split("-")[2] + "-" + payment.paymentDate.split("T")[0].split("-")[1] + "-" + payment.paymentDate.split("T")[0].split("-")[0] : "",
                        concepto: payment.concept,
                        vencimiento: payment.expiration.split("T")[0].split('-')[2] + "-" + payment.expiration.split("T")[0].split('-')[1] + "-" + payment.expiration.split("T")[0].split('-')[0],
                        retrazo: payment.delay.toString() + " días",
                        apagar: payment.price,
                        pago: payment.paymentAmount ? payment.paymentAmount : 0,
                        estado: payment.paymentStatus,
                        id: payment._id,
                    })
                }
                setCuotesQtyExpired(qtyCuotesExpired)
                setPaymentsData(payments)
            })
            .catch(error => {
                // console.log(error)
            })

        clienteAxios.get(`/api/space/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                let data = res.data
                setSpaceData(res.data)

                let header = [
                    data.client.name + " " + data.client.lastname,
                    data.lote,
                    data.mensura,
                    `${data.cuotas} en ${data.divisa}`,
                    data.barrio,
                    formatterPeso.format(data.saldoTotal),
                    formatterPeso.format(data.saldoALaFecha),
                    formatterPeso.format(data.pagoTotal)
                ]
                // console.log(header)
                setDataHeaderExcel(header)
            })
            .catch(error => {
                // console.log(error)
            })
    }


    const [columns] = React.useState([
        { name: 'fecha', title: 'Fecha de pago' },
        { name: 'concepto', title: 'Concepto' },
        { name: 'vencimiento', title: 'Vencimiento' },
        { name: 'retrazo', title: 'Retraso' },
        { name: 'apagar', title: 'A pagar ($)' },
        { name: 'pago', title: 'Pago' },
        { name: 'estado', title: 'Estado' },
    ]);

    const [tableColumnExtensions] = useState([
        { columnName: 'fecha', width: 250 },
        { columnName: 'concepto', width: 250 },
        { columnName: 'vencimiento', width: 250 },
        { columnName: 'retrazo', width: 250 },
        { columnName: 'apagar', width: 250 },
        { columnName: 'pago', width: 250 },
        { columnName: 'estado', width: 250 },
    ]);

    const [editingStateColumnExtensions] = useState([
        { columnName: 'fecha', editingEnabled: true },
        { columnName: 'concepto', editingEnabled: false },
        { columnName: 'vencimiento', editingEnabled: false },
        { columnName: 'retrazo', editingEnabled: false },
        { columnName: 'apagar', editingEnabled: false },
        { columnName: 'pago', editingEnabled: true },
        { columnName: 'estado', editingEnabled: false },
    ]);

    const [lookupColumns] = useState(["fecha"]);

    const HighlightedCell = ({ value, style, ...restProps }) => (
        <Table.Cell {...restProps}>
            <span style={{ color: value === "Vencido" ? '#DD3434' : "#039487" }}>
                {value}
            </span>
        </Table.Cell>
    );

    const Cell = (props) => {
        const { column } = props;
        if (column.name === 'estado') {
            return <HighlightedCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };


    const LookupEditor = ({ value, onValueChange }) => {
        return (
            <Form.Control
                id="date"
                type="date"
                value={value}
                onChange={e => {
                    onValueChange(e.target.value);
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
        );
    };

    const LookupFormatter = props => (
        <DataTypeProvider
            editorComponent={params => <LookupEditor {...params} />}
            {...props}
        />
    );


    const commitChanges = async ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = paymentsData.length > 0 ? paymentsData[paymentsData.length - 1].id + 1 : 0;
            changedRows = [
                ...paymentsData,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            // console.log(changed)

            let promise = new Promise((resolve, reject) => {

                const config = {
                    headers: { 'Authorization': "Bearer " + token }
                };

                let idRow;
                let body;
                for (var key1 in changed) {
                    // console.log(key1);
                    idRow = key1;
                    for (var key2 in changed[key1]) {
                        // console.log(key2);
                        body = {
                            paymentAmount: changed[key1].pago,
                            paymentDate: changed[key1].fecha,
                        }
                    }
                }

                // console.log(body)

                if (body.paymentAmount && body.paymentDate) {
                    clienteAxios.put(`/api/payment/${idRow}`, body, config)
                        .then((res) => {
                            // console.log(res.data)
                            changedRows = paymentsData.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
                            Toast.fire({ icon: 'success', title: 'Editado correctamente' })
                            resolve("done!")
                            getDataTableAfterUpdate()
                        })
                        .catch(error => {
                            Toast.fire({ icon: 'error', title: 'Error al editar' })
                            reject()
                        })
                } else {
                    changedRows = paymentsData.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
                    Swal.fire({ icon: 'error', title: 'Fecha y monto de pago requerido!' })
                    reject()
                }
            });

            let result = await promise; // wait until the promise resolves (*)
            console.log(result); // "done!"

        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = paymentsData.filter(row => !deletedSet.has(row.id));
        }
        setPaymentsData(changedRows);
    };



    const formatterPeso = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
    })

    const customizeHeader = (worksheet) => {
        const generalStyles = {
            font: { bold: true },
            fill: {
                type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' }, bgColor: { argb: 'D3D3D3' },
            },
            alignment: { horizontal: 'left' },
        };
        for (let rowIndex = 1; rowIndex < 9; rowIndex += 1) {
            worksheet.mergeCells(rowIndex, 1, rowIndex, 3);
            worksheet.mergeCells(rowIndex, 4, rowIndex, 6);
            Object.assign(worksheet.getRow(rowIndex).getCell(1), generalStyles);
            Object.assign(worksheet.getRow(rowIndex).getCell(3), generalStyles);
        }
        worksheet.getRow(1).height = 20;
        worksheet.getRow(1).getCell(1).font = { bold: true, size: 16 };
        worksheet.getRow(1).getCell(4).numFmt = 'd mmmm yyyy';
        worksheet.getRow(1).getCell(4).font = { bold: true, size: 16 };
        worksheet.getColumn(1).values = ['Cliente:', 'Lote:', 'Mensura:', 'Cuotas:', 'Barrio:', 'Saldo total:', 'Saldo deudor:', 'Pago total:'];
        worksheet.getColumn(4).values = dataHeaderExcel;
        worksheet.addRow({});
    };

    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
    };

    const deleteClient = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Esta opción no podrá revertirse",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1D2146',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteClientFn(id, token)
            }
        })
    }

    const uploadExcelUpdate = async () => {
        const { value: file } = await Swal.fire({
            title: 'Seleccionar archivo',
            input: 'file',
            inputAttributes: {
                'accept': '.xlsx, .xls, .csv',
                'aria-label': 'Selecciona un archivo excel'
            }
        })

        if (file) {
            let formData = new FormData();
            formData.append('excelFile', file);

            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };

            clienteAxios.put(`/api/payment/excel/space/${id}`, formData, config)
                .then((res) => {
                    // console.log(res.data)
                    getDataTableAfterUpdate()
                })
                .catch(error => {
                    // console.log(error)
                })

        }
    }

    return (
        <React.Fragment>
            <Info className="mb-4">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className="d-flex">
                                <H3 className="me-2">Cliente: </H3>
                                <P>{spaceData ? spaceData.client.name + " " + spaceData.client.lastname : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Lote: </H3>
                                <P>{spaceData ? spaceData.lote : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Mensura: </H3>
                                <P>{spaceData ? spaceData.mensura : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Cuotas: </H3>
                                <P>{spaceData ? spaceData.cuotas : ""} en {spaceData ? spaceData.divisa : ""}</P>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="d-flex">
                                <H3 className="me-2">Barrio: </H3>
                                <P>{spaceData ? spaceData.barrio : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Saldo total: </H3>
                                <P>{formatterPeso.format(spaceData ? spaceData.saldoTotal : "")}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Saldo deudor: </H3>
                                <P>{formatterPeso.format(spaceData ? spaceData.saldoALaFecha : "")}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Pago total: </H3>
                                <P>{formatterPeso.format(spaceData ? spaceData.pagoTotal : "")}</P>
                            </div>
                        </Col>
                        <Col className="px-0 d-flex align-content-between " lg={4}>
                            <div class="d-flex flex-column mb-3">
                                <div className="d-flex">
                                    <H3 className="me-2">Cuotas vencidas: </H3>
                                    <P>{cuotesQtyExpired}</P>
                                </div>
                                <div className="">
                                    <H3 style={{ cursor: "pointer", color: "#039487" }} onClick={() => uploadExcelUpdate()}> Actualizar via Excel</H3>
                                </div>
                                <div className="d-flex">
                                    <H3 style={{ cursor: "pointer", color: "#DD3434" }} onClick={() => deleteClient()}> Eliminar</H3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Info>
            <div className="card">
                <Grid rows={paymentsData} columns={columns} getRowId={getRowId}>
                    <LookupFormatter for={lookupColumns} />
                    <SearchState />
                    <IntegratedFiltering />
                    <EditingState
                        onCommitChanges={commitChanges}
                        columnExtensions={editingStateColumnExtensions}
                    />
                    <Table />
                    <VirtualTable height={430} cellComponent={Cell} />
                    <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
                    <TableHeaderRow />
                    <Toolbar />
                    <ExportPanel startExport={startExport} />
                    <SearchPanel />
                    <TableEditRow />
                    {role === "superAdmin" && (
                        <TableEditColumn
                            showEditCommand
                        />
                    )}
                </Grid>
                <GridExporter
                    ref={exporterRef}
                    rows={paymentsData}
                    columns={columns}
                    onSave={onSave}
                    customizeHeader={customizeHeader}
                />
            </div>
        </React.Fragment>
    );
}

export default Expiration;
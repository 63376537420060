import {
    DELETE_CLIENT_START,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAIL,
} from './actionsType';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';
import { navigate } from 'gatsby';


export function DeleteClientAction(id, token) {
    return (dispatch) => {
        dispatch(DeleteClientStart())

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.delete(`/api/space/${id}`, config)
            .then((res) => {
                const response = res.data;
                // console.log(response)
                dispatch(DeleteClientSuccess())
                Swal.fire({
                    title: 'Exito!',
                    text: "Cliente eliminado.",
                    icon: 'success',
                    confirmButtonColor: '#1D2146'
                })
                navigate('/dashboard/clientes')
            })
            .catch(error => {
                dispatch(DeleteClientFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}
export const DeleteClientStart = () => {
    return {
        type: DELETE_CLIENT_START
    };
};

export const DeleteClientSuccess = () => ({
    type: DELETE_CLIENT_SUCCESS
})

export const DeleteClientFail = () => ({
    type: DELETE_CLIENT_FAIL,
})